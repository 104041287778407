import React, { useState, useLayoutEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import useWindowSize from "../windowSize";
import LogoCloud from "../components/Repeating/LogoCloud";
import Testimonials from "../components/Repeating/Testimonials";
import ServicesGrid from "../components/Repeating/ServicesGrid";
import ValueProps from "../components/Repeating/ValueProps";
import CallToAction from "../components/Repeating/CTA";
import ButtonGhost from "../components/Button/ButtonGhost";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";

const Page = ({ data }) => {
  const { width } = useWindowSize();
  const [parallaxDisabled, setParallaxDisabled] = useState(false);

  useLayoutEffect(() => {
    if (width < 768) {
      setParallaxDisabled(true);
    } else {
      setParallaxDisabled(false);
    }
  });

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Los Angeles Hospitality Staffing | Hotel Cleaning Services"
        description="For top-tier Los Angeles hospitality staffing, look no further. See why Hotel Cleaning Services is a nationwide leader in the industry. Learn more!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative mb-24 md:mb-40">
        <div className="container">
          <div className="grid md:grid-cols-12 gap-y-4 md:h-[724px]">
            <div className="md:col-start-1 md:col-span-11 order-2 md:order-1 md:pt-64 md:max-w-3xl z-10">
              <p className="font-heading text-mobile-7xl md:text-7xl uppercase mb-4 md:mb-8">
                Pristine clean since 1983.
              </p>
              <p className="text-sm md:text-base tracking-wider text-white mb-8 md:max-w-md">
                The leader in hotel cleaning & hospitality staffing for Los
                Angeles.
              </p>
              <ButtonGhost modal="modal-contact" text="Contact Us" />
            </div>
            <div className="md:col-end-13 md:col-span-1 order-1 md:order-2 md:absolute md:right-0 md:h-full -mx-4 md:mx-0">
              <GatsbyImage
                image={data.hero.childImageSharp.gatsbyImageData}
                className="md:h-full md:w-[55vw]"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-24 md:mb-64">
        <div className="container">
          <div className="grid md:grid-cols-12 gap-y-6 md:gap-x-10 lg:gap-x-18 items-center">
            <div className="relative md:col-start-1 md:col-span-7 mr-8 md:mr-0">
              <ParallaxProvider>
                <Parallax y={[25, -15]} disabled={parallaxDisabled}>
                  <GatsbyImage
                    image={data.intro.childImageSharp.gatsbyImageData}
                    width="606"
                  />
                </Parallax>
              </ParallaxProvider>
              <div className="border border-primary-600 max-w-[585px] max-h-[576px] w-full h-full absolute top-10 md:top-18 left-10 md:left-26"></div>
            </div>

            <header className="md:col-end-13 md:col-span-5">
              <h1 className="relative md:-left-24 lg:-left-48 lg:w-[700px]">
                Premier Hotel Cleaning & Hospitality Staffing in Los Angeles
              </h1>
              <p className="text-sm md:text-base mb-0 ml-6 md:ml-0">
                Hotel Cleaning Services is your trusted source for skilled
                hospitality staffing, exceptional housekeeping services, and
                specialty cleaning. We’re proud to be a part of what makes Los
                Angeles a unique place. For over 30 years, we have provided
                superior service and staffing for top hotel and resort brands
                and commercial properties both locally and nationwide.
              </p>
            </header>
          </div>
        </div>
      </section>

      <LogoCloud />

      <ServicesGrid />

      <section className="mb-24 md:mb-32">
        <div className="container">
          <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-18 items-center">
            <header className="md:col-start-1 md:col-span-5">
              <div className="relative lg:mb-7 max-w-sm md:max-w-md z-10">
                <img
                  src={data.thirtyYearsSVG.publicURL}
                  alt="30+ Years of Excellence"
                />
                <h2 className="lg:text-3xl xl:text-5xl absolute left-32 lg:left-20 xl:left-28 lg:w-full -bottom-6 lg:bottom-0 mb-0">
                  <span className="hidden">30+</span> Years of
                  <br className="lg:hidden" /> Excellence
                </h2>
              </div>

              <div className="relative flex justify-end lg:hidden ml-10 -mt-3 mb-14">
                <GatsbyImage
                  image={data.thirtyYearsImage.childImageSharp.gatsbyImageData}
                  width="560"
                  className="ml-auto"
                />
                <div className="border border-primary-600 max-w-[585px] max-h-[545px] w-full h-full absolute -bottom-8 -left-6"></div>
              </div>

              <p className="text-sm md:text-base mb-4 md:mb-10 md:max-w-xs">
                Our success story began in 1983 with founder John Knoepker's
                vision to reshape the cleaning services industry. He succeeded.
                Today, Hotel Cleaning Services is best-in-class in hotel
                cleaning, staffing, and management.
              </p>
              <ButtonWithIcon
                href="/about/"
                text={["For More On ", <br />, "Our Story"]}
                su
              />
            </header>

            <div className="relative md:col-end-13 md:col-span-7 flex justify-end hidden lg:block">
              <ParallaxProvider>
                <Parallax
                  y={[25, -15]}
                  disabled={parallaxDisabled}
                  className="flex justify-end"
                >
                  <GatsbyImage
                    image={
                      data.thirtyYearsImage.childImageSharp.gatsbyImageData
                    }
                    width="560"
                  />
                </Parallax>
              </ParallaxProvider>
              <div className="border border-primary-600 max-w-[585px] max-h-[545px] w-full h-full absolute top-14 right-10"></div>
            </div>
          </div>
        </div>
      </section>

      <ValueProps />
      <Testimonials />
      <CallToAction className="mb-20 md:mb-32" />

      <section className="pb-24 md:pb-40">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-10 lg:gap-x-20 items-center">
            <div>
              <GatsbyImage
                image={data.city.childImageSharp.gatsbyImageData}
                width="560"
              />
            </div>

            <div>
              <h2>What We Love About Los Angeles</h2>
              <p className="mb-0">
                Los Angeles is a sprawling city known as the entertainment
                capital of the world. It’s where major movie studios produce the
                films we love, and the rich and famous reside. It’s also the
                second-largest city in the U.S. (second to New York City) and is
                home to the fifth busiest airport in the world, LAX. There is
                much to see and do in Los Angeles, and at Hotel Cleaning
                Services, we are proud to serve the city’s fine hotels and
                resorts, as well as its commercial properties.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    hero: file(relativePath: { eq: "home/1.0 Hero desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 817)
      }
    }
    intro: file(relativePath: { eq: "home/2.0 home intro desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 606)
      }
    }
    thirtyYearsSVG: file(
      relativePath: { eq: "home/5.0 30+ years of excellence .svg" }
    ) {
      publicURL
    }
    thirtyYearsImage: file(
      relativePath: { eq: "home/5.0 30+ years of excellence.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    city: file(relativePath: { eq: "cities/Los Angeles.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
  }
`;
export default Page;
